@import 'src/ui-kit/styles/colors';

.no-data {
  text-align: center;
  margin-bottom: 20px;

  &__icon {
    font-size: 60px;
    color: $elo-grey-light;
    margin: 20px 0 15px;
  }

  &__message {
    font-size: 13px;
    color: $elo-grey;
  }

  &--in-collapsible-row {
    .no-data__icon {
      color: $elo-grey-40;
    }
  }
}
