.elo-accordion {
  --elo-accordion-background-hover: var(--color-primary-dg-100);
  --elo-accordion-header-color: var(--color-primary-dg-brand);
  --elo-accordion-content-color: var(--color-primary-dg-500);
  --elo-accordion-content-font: var(--elo-body-xs-regular);
  --elo-accordion-header-font: var(--elo-heading-h6);
  --elo-accordion-header-font-medium: var(--elo-body-sm-medium);
  --elo-accordion-header-font-small: var(--elo-body-xs-regular);
  --elo-accordion-header-font-large: var(--elo-heading-h5);

  display: block;
  box-sizing: border-box;

  &__header {
    display: flex;
    font: var(--elo-accordion-header-font);
    position: relative;
    color: var(--elo-accordion-header-color);
    margin: 0;
    border-radius: 8px;
    padding: 16px 30px 16px 16px;
    font-size: 18px;

    &:hover {
      cursor: pointer;
      background-color: var(--elo-accordion-background-hover);
      border-radius: 0;
    }

    &.elo-accordion__header--disabled {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
        background-color: initial;
      }
    }
  }

  &__caret_icon {
    position: absolute;
    right: 8px;
    top: 16px;
  }

  &__content {
    font: var(--elo-accordion-content-font);
    color: var(--elo-accordion-content-color);
    overflow: hidden;
    max-height: 0;
    font-size: 14px;

    > div {
      margin: 0;
      padding: 8px 16px 0 16px;
    }

    &--collapsed {
      max-height: 300vh;
    }

    &--short-description {
      max-height: unset;
      padding-left: 28px;
      padding-right: 28px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  & > div:has(.elo-accordion__content--short-description > div),
  & div > .elo-accordion__content--collapsed {
    padding-bottom: 16px;
  }

  &__medium {
    .elo-accordion__header {
      font: var(--elo-accordion-header-font-medium);
      padding: 12px 30px 12px 16px;
      font-size: 14px;
    }

    .elo-accordion__caret_icon {
      top: 10px;
    }

    .elo-accordion__content {
      font-size: 14px;
    }
  }

  &__small {
    .elo-accordion__header {
      font: var(--elo-accordion-header-font-small);
      padding: 8px 30px 8px 16px;
      font-size: 12px;
    }

    .elo-accordion__caret_icon {
      top: 6px;
    }

    .elo-accordion__content {
      font-size: 12px;
    }
  }

  &__large {
    .elo-accordion__header {
      font: var(--elo-accordion-header-font-large);
      padding: 24px 40px 24px 24px;
    }

    .elo-accordion__caret_icon {
      top: 26px;
      right: 20px;
    }

    .elo-accordion__content > div {
      margin: 0;
      padding: 8px 24px 16px;
    }
  }

  &__image {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    object-fit: cover;

    &--large {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
  }

  &--elevation-1 {
    box-shadow: var(--shadow-elevation-1);
  }

  &--elevation-2 {
    box-shadow: var(--shadow-elevation-2);
  }
}
