@import '../../../../../src/styles/rootPath.js';

.elo-input--phone__drop-down {
  .country {
    position: relative;
  }
  .dial-code {
    position: absolute;
    left: 54px;
    top: 27px;
    font-size: 12px;
    color: #71747a;
  }
  .country-name {
    position: absolute;
    left: 54px;
    top: 10px;
    font-size: 14px;
    color: #21282e;
  }
}

.elo-input--phone .flag {
  background-position: center;
  background-size: initial;
  width: 22px;
  height: 16px;
  position: relative;
  top: -1px;
  left: -3px;
}
.selected-flag .flag {
  top: 21px !important;
  background-image: url($rootPath + 'src/images/flags_sprite.png');
  background-size: 220px 440px;
}

.react-tel-input .country-list .flag {
  background-image: url($rootPath + 'src/images/flags_sprite.png');
  background-size: 220px 440px;
}

.elo-input--phone .ad {
  background-position: -0px -0px;
}

.elo-input--phone .ae {
  background-position: -0px -16.3px;
}

.elo-input--phone .af {
  background-position: -0px -32.6px;
}

.elo-input--phone .ag {
  background-position: -0px -48.9px;
}

.elo-input--phone .ai {
  background-position: -0px -65.2px;
}

.elo-input--phone .al {
  background-position: -0px -81.5px;
}

.elo-input--phone .am {
  background-position: -0px -97.8px;
}

.elo-input--phone .ao {
  background-position: -0px -114.1px;
}

.elo-input--phone .aq {
  background-position: -0px -130.4px;
}

.elo-input--phone .ar {
  background-position: -0px -146.7px;
}

.elo-input--phone .as {
  background-position: -0px -163px;
}

.elo-input--phone .at {
  background-position: -0px -179.3px;
}

.elo-input--phone .au {
  background-position: -0px -195.6px;
}

.elo-input--phone .aw {
  background-position: -0px -211.9px;
}

.elo-input--phone .ax {
  background-position: -0px -228.2px;
}

.elo-input--phone .az {
  background-position: -0px -244.5px;
}

.elo-input--phone .ba {
  background-position: -0px -260.8px;
}

.elo-input--phone .bb {
  background-position: -0px -277.1px;
}

.elo-input--phone .bd {
  background-position: -0px -293.4px;
}

.elo-input--phone .be {
  background-position: -0px -309.7px;
}

.elo-input--phone .bf {
  background-position: -0px -326px;
}

.elo-input--phone .bg {
  background-position: -0px -342.3px;
}

.elo-input--phone .bh {
  background-position: -0px -358.6px;
}

.elo-input--phone .bi {
  background-position: -0px -374.9px;
}

.elo-input--phone .bj {
  background-position: -0px -391.2px;
}

.elo-input--phone .bl {
  background-position: -0px -407.5px;
}

.elo-input--phone .bm {
  background-position: -0px -423.8px;
}

.elo-input--phone .bn {
  background-position: -22px -0px;
}

.elo-input--phone .bo {
  background-position: -22px -16.3px;
}

.elo-input--phone .bq {
  background-position: -22px -32.6px;
}

.elo-input--phone .bq-sab {
  background-position: -22px -48.9px;
}

.elo-input--phone .bq-bes {
  background-position: -22px -65.2px;
}

.elo-input--phone .br {
  background-position: -22px -81.5px;
}

.elo-input--phone .bs {
  background-position: -22px -97.8px;
}

.elo-input--phone .bt {
  background-position: -22px -114.1px;
}

.elo-input--phone .bv {
  background-position: -22px -130.4px;
}

.elo-input--phone .bw {
  background-position: -22px -146.7px;
}

.elo-input--phone .by {
  background-position: -22px -163px;
}

.elo-input--phone .bz {
  background-position: -22px -179.3px;
}

.elo-input--phone .ca {
  background-position: -22px -195.6px;
}

.elo-input--phone .cc {
  background-position: -22px -211.9px;
}

.elo-input--phone .cd {
  background-position: -22px -228.2px;
}

.elo-input--phone .cf {
  background-position: -22px -244.5px;
}

.elo-input--phone .cg {
  background-position: -22px -260.8px;
}

.elo-input--phone .ch {
  background-position: -22px -277.1px;
}

.elo-input--phone .ci {
  background-position: -22px -293.4px;
}

.elo-input--phone .ck {
  background-position: -22px -309.7px;
}

.elo-input--phone .cl {
  background-position: -22px -326px;
}

.elo-input--phone .cm {
  background-position: -22px -342.3px;
}

.elo-input--phone .cn {
  background-position: -22px -358.6px;
}

.elo-input--phone .co {
  background-position: -22px -374.9px;
}

.elo-input--phone .cr {
  background-position: -22px -391.2px;
}

.elo-input--phone .cu {
  background-position: -22px -407.5px;
}

.elo-input--phone .cv {
  background-position: -22px -423.8px;
}

.elo-input--phone .cw {
  background-position: -44px -0px;
}

.elo-input--phone .cx {
  background-position: -44px -16.3px;
}

.elo-input--phone .cy {
  background-position: -44px -32.6px;
}

.elo-input--phone .cz {
  background-position: -44px -48.9px;
}

.elo-input--phone .de {
  background-position: -44px -65.2px;
}

.elo-input--phone .dj {
  background-position: -44px -81.5px;
}

.elo-input--phone .dk {
  background-position: -44px -97.8px;
}

.elo-input--phone .dm {
  background-position: -44px -114.1px;
}

.elo-input--phone .do {
  background-position: -44px -130.4px;
}

.elo-input--phone .dz {
  background-position: -44px -146.7px;
}

.elo-input--phone .ec {
  background-position: -44px -163px;
}

.elo-input--phone .ee {
  background-position: -44px -179.3px;
}

.elo-input--phone .eg {
  background-position: -44px -195.6px;
}

.elo-input--phone .eh {
  background-position: -44px -211.9px;
}

.elo-input--phone .er {
  background-position: -44px -228.2px;
}

.elo-input--phone .es {
  background-position: -44px -244.5px;
}

.elo-input--phone .et {
  background-position: -44px -260.8px;
}

.elo-input--phone .fi {
  background-position: -44px -277.1px;
}

.elo-input--phone .fj {
  background-position: -44px -293.4px;
}

.elo-input--phone .fk {
  background-position: -44px -309.7px;
}

.elo-input--phone .fm {
  background-position: -44px -326px;
}

.elo-input--phone .fo {
  background-position: -44px -342.3px;
}

.elo-input--phone .fr {
  background-position: -44px -358.6px;
}

.elo-input--phone .ga {
  background-position: -44px -374.9px;
}

.elo-input--phone .gb-eng {
  background-position: -44px -391.2px;
}

.elo-input--phone .gb-nir {
  background-position: -44px -407.5px;
}

.elo-input--phone .gb-sct {
  background-position: -44px -423.8px;
}

.elo-input--phone .gb {
  background-position: -66px -0px;
}

.elo-input--phone .gb-wls {
  background-position: -66px -16.3px;
}

.elo-input--phone .gd {
  background-position: -66px -32.6px;
}

.elo-input--phone .ge {
  background-position: -66px -48.9px;
}

.elo-input--phone .gf {
  background-position: -66px -65.2px;
}

.elo-input--phone .gg {
  background-position: -66px -81.5px;
}

.elo-input--phone .gh {
  background-position: -66px -97.8px;
}

.elo-input--phone .gi {
  background-position: -66px -114.1px;
}

.elo-input--phone .gl {
  background-position: -66px -130.4px;
}

.elo-input--phone .gm {
  background-position: -66px -146.7px;
}

.elo-input--phone .gn {
  background-position: -66px -163px;
}

.elo-input--phone .gp {
  background-position: -66px -179.3px;
}

.elo-input--phone .gp-2 {
  background-position: -66px -195.6px;
}

.elo-input--phone .gq {
  background-position: -66px -211.9px;
}

.elo-input--phone .gr {
  background-position: -66px -228.2px;
}

.elo-input--phone .gs {
  background-position: -66px -244.5px;
}

.elo-input--phone .gt {
  background-position: -66px -260.8px;
}

.elo-input--phone .gu {
  background-position: -66px -277.1px;
}

.elo-input--phone .gw {
  background-position: -66px -293.4px;
}

.elo-input--phone .gy {
  background-position: -66px -309.7px;
}

.elo-input--phone .cn-hk {
  background-position: -66px -326px;
}

.elo-input--phone .hm {
  background-position: -66px -342.3px;
}

.elo-input--phone .hn {
  background-position: -66px -358.6px;
}

.elo-input--phone .hr {
  background-position: -66px -374.9px;
}

.elo-input--phone .ht {
  background-position: -66px -391.2px;
}

.elo-input--phone .hu {
  background-position: -66px -407.5px;
}

.elo-input--phone .id {
  background-position: -66px -423.8px;
}

.elo-input--phone .ie {
  background-position: -88px -0px;
}

.elo-input--phone .il {
  background-position: -88px -16.3px;
}

.elo-input--phone .im {
  background-position: -88px -32.6px;
}

.elo-input--phone .in {
  background-position: -88px -48.9px;
}

.elo-input--phone .io {
  background-position: -88px -65.2px;
}

.elo-input--phone .iq {
  background-position: -88px -81.5px;
}

.elo-input--phone .ir {
  background-position: -88px -97.8px;
}

.elo-input--phone .is {
  background-position: -88px -114.1px;
}

.elo-input--phone .it {
  background-position: -88px -130.4px;
}

.elo-input--phone .je {
  background-position: -88px -146.7px;
}

.elo-input--phone .jm {
  background-position: -88px -163px;
}

.elo-input--phone .jo {
  background-position: -88px -179.3px;
}

.elo-input--phone .jp {
  background-position: -88px -195.6px;
}

.elo-input--phone .kg {
  background-position: -88px -211.9px;
}

.elo-input--phone .kh {
  background-position: -88px -228.2px;
}

.elo-input--phone .ki {
  background-position: -88px -244.5px;
}

.elo-input--phone .km {
  background-position: -88px -260.8px;
}

.elo-input--phone .ke {
  background-position: -88px -277.1px;
}

.elo-input--phone .kn {
  background-position: -88px -293.4px;
}

.elo-input--phone .kp {
  background-position: -88px -309.7px;
}

.elo-input--phone .kr {
  background-position: -88px -326px;
}

.elo-input--phone .kw {
  background-position: -88px -342.3px;
}

.elo-input--phone .ky {
  background-position: -88px -358.6px;
}

.elo-input--phone .kz {
  background-position: -88px -374.9px;
}

.elo-input--phone .la {
  background-position: -88px -391.2px;
}

.elo-input--phone .lb {
  background-position: -88px -407.5px;
}

.elo-input--phone .lc {
  background-position: -88px -423.8px;
}

.elo-input--phone .li {
  background-position: -110px -0px;
}

.elo-input--phone .lk {
  background-position: -110px -16.3px;
}

.elo-input--phone .lr {
  background-position: -110px -32.6px;
}

.elo-input--phone .ls {
  background-position: -110px -48.9px;
}

.elo-input--phone .lt {
  background-position: -110px -65.2px;
}

.elo-input--phone .lu {
  background-position: -110px -81.5px;
}

.elo-input--phone .lv {
  background-position: -110px -97.8px;
}

.elo-input--phone .ly {
  background-position: -110px -114.1px;
}

.elo-input--phone .ma {
  background-position: -110px -130.4px;
}

.elo-input--phone .mc {
  background-position: -110px -146.7px;
}

.elo-input--phone .md {
  background-position: -110px -163px;
}

.elo-input--phone .me {
  background-position: -110px -179.3px;
}

.elo-input--phone .mf {
  background-position: -110px -195.6px;
}

.elo-input--phone .mg {
  background-position: -110px -211.9px;
}

.elo-input--phone .mh {
  background-position: -110px -228.2px;
}

.elo-input--phone .mk {
  background-position: -110px -244.5px;
}

.elo-input--phone .ml {
  background-position: -110px -260.8px;
}

.elo-input--phone .mm {
  background-position: -110px -277.1px;
}

.elo-input--phone .mn {
  background-position: -110px -293.4px;
}

.elo-input--phone .mo {
  background-position: -110px -309.7px;
}

.elo-input--phone .mp {
  background-position: -110px -326px;
}

.elo-input--phone .mq {
  background-position: -110px -342.3px;
}

.elo-input--phone .mq-2 {
  background-position: -110px -358.6px;
}

.elo-input--phone .mr {
  background-position: -110px -374.9px;
}

.elo-input--phone .ms {
  background-position: -110px -391.2px;
}

.elo-input--phone .mt {
  background-position: -110px -407.5px;
}

.elo-input--phone .mu {
  background-position: -110px -423.8px;
}

.elo-input--phone .mv {
  background-position: -132px -0px;
}

.elo-input--phone .mw {
  background-position: -132px -16.3px;
}

.elo-input--phone .mx {
  background-position: -132px -32.6px;
}

.elo-input--phone .my {
  background-position: -132px -48.9px;
}

.elo-input--phone .mz {
  background-position: -132px -65.2px;
}

.elo-input--phone .na {
  background-position: -132px -81.5px;
}

.elo-input--phone .nc {
  background-position: -132px -97.8px;
}

.elo-input--phone .ne {
  background-position: -132px -114.1px;
}

.elo-input--phone .nf {
  background-position: -132px -130.4px;
}

.elo-input--phone .ng {
  background-position: -132px -146.7px;
}

.elo-input--phone .ni {
  background-position: -132px -163px;
}

.elo-input--phone .nl {
  background-position: -132px -179.3px;
}

.elo-input--phone .no {
  background-position: -132px -195.6px;
}

.elo-input--phone .np {
  background-position: -132px -211.9px;
}

.elo-input--phone .nr {
  background-position: -132px -228.2px;
}

.elo-input--phone .nu {
  background-position: -132px -244.5px;
}

.elo-input--phone .nz {
  background-position: -132px -260.8px;
}

.elo-input--phone .om {
  background-position: -132px -277.1px;
}

.elo-input--phone .pa {
  background-position: -132px -293.4px;
}

.elo-input--phone .pe {
  background-position: -132px -309.7px;
}

.elo-input--phone .pf {
  background-position: -132px -326px;
}

.elo-input--phone .pg {
  background-position: -132px -342.3px;
}

.elo-input--phone .ph {
  background-position: -132px -358.6px;
}

.elo-input--phone .pk {
  background-position: -132px -374.9px;
}

.elo-input--phone .pl {
  background-position: -132px -391.2px;
}

.elo-input--phone .pm {
  background-position: -132px -407.5px;
}

.elo-input--phone .pm-2 {
  background-position: -132px -423.8px;
}

.elo-input--phone .pn {
  background-position: -154px -0px;
}

.elo-input--phone .pr {
  background-position: -154px -16.3px;
}

.elo-input--phone .ps {
  background-position: -154px -32.6px;
}

.elo-input--phone .pt {
  background-position: -154px -48.9px;
}

.elo-input--phone .pw {
  background-position: -154px -65.2px;
}

.elo-input--phone .py {
  background-position: -154px -81.5px;
}

.elo-input--phone .qa {
  background-position: -154px -97.8px;
}

.elo-input--phone .re {
  background-position: -154px -114.1px;
}

.elo-input--phone .ro {
  background-position: -154px -130.4px;
}

.elo-input--phone .rs {
  background-position: -154px -146.7px;
}

.elo-input--phone .ru {
  background-position: -154px -163px;
}

.elo-input--phone .rw {
  background-position: -154px -179.3px;
}

.elo-input--phone .sa {
  background-position: -154px -195.6px;
}

.elo-input--phone .sb {
  background-position: -154px -211.9px;
}

.elo-input--phone .sc {
  background-position: -154px -228.2px;
}

.elo-input--phone .sd {
  background-position: -154px -244.5px;
}

.elo-input--phone .se {
  background-position: -154px -260.8px;
}

.elo-input--phone .sg {
  background-position: -154px -277.1px;
}

.elo-input--phone .sh {
  background-position: -154px -293.4px;
}

.elo-input--phone .si {
  background-position: -154px -309.7px;
}

.elo-input--phone .sj {
  background-position: -154px -326px;
}

.elo-input--phone .sk {
  background-position: -154px -342.3px;
}

.elo-input--phone .sl {
  background-position: -154px -358.6px;
}

.elo-input--phone .sm {
  background-position: -154px -374.9px;
}

.elo-input--phone .sn {
  background-position: -154px -391.2px;
}

.elo-input--phone .so {
  background-position: -154px -407.5px;
}

.elo-input--phone .sr {
  background-position: -154px -423.8px;
}

.elo-input--phone .ss {
  background-position: -176px -0px;
}

.elo-input--phone .st {
  background-position: -176px -16.3px;
}

.elo-input--phone .sv {
  background-position: -176px -32.6px;
}

.elo-input--phone .sx {
  background-position: -176px -48.9px;
}

.elo-input--phone .sy {
  background-position: -176px -65.2px;
}

.elo-input--phone .sz {
  background-position: -176px -81.5px;
}

.elo-input--phone .tc {
  background-position: -176px -97.8px;
}

.elo-input--phone .td {
  background-position: -176px -114.1px;
}

.elo-input--phone .tf {
  background-position: -176px -130.4px;
}

.elo-input--phone .tg {
  background-position: -176px -146.7px;
}

.elo-input--phone .th {
  background-position: -176px -163px;
}

.elo-input--phone .tj {
  background-position: -176px -179.3px;
}

.elo-input--phone .tk {
  background-position: -176px -195.6px;
}

.elo-input--phone .tl {
  background-position: -176px -211.9px;
}

.elo-input--phone .tm {
  background-position: -176px -228.2px;
}

.elo-input--phone .tn {
  background-position: -176px -244.5px;
}

.elo-input--phone .to {
  background-position: -176px -260.8px;
}

.elo-input--phone .tr {
  background-position: -176px -277.1px;
}

.elo-input--phone .tt {
  background-position: -176px -293.4px;
}

.elo-input--phone .tv {
  background-position: -176px -309.7px;
}

.elo-input--phone .tw {
  background-position: -176px -326px;
}

.elo-input--phone .tz {
  background-position: -176px -342.3px;
}

.elo-input--phone .ua {
  background-position: -176px -358.6px;
}

.elo-input--phone .ug {
  background-position: -176px -374.9px;
}

.elo-input--phone .um {
  background-position: -176px -391.2px;
}

.elo-input--phone .us {
  background-position: -176px -407.5px;
}

.elo-input--phone .uy {
  background-position: -176px -423.8px;
}

.elo-input--phone .uz {
  background-position: -198px -0px;
}

.elo-input--phone .va {
  background-position: -198px -16.3px;
}

.elo-input--phone .vc {
  background-position: -198px -32.6px;
}

.elo-input--phone .ve {
  background-position: -198px -48.9px;
}

.elo-input--phone .vg {
  background-position: -198px -65.2px;
}

.elo-input--phone .vi {
  background-position: -198px -81.5px;
}

.elo-input--phone .vi-2 {
  background-position: -198px -97.8px;
}

.elo-input--phone .vn {
  background-position: -198px -114.1px;
}

.elo-input--phone .vu {
  background-position: -198px -130.4px;
}

.elo-input--phone .wf {
  background-position: -198px -146.7px;
}

.elo-input--phone .ws {
  background-position: -198px -163px;
}

.elo-input--phone .ye {
  background-position: -198px -179.3px;
}

.elo-input--phone .yt {
  background-position: -198px -195.6px;
}

.elo-input--phone .za {
  background-position: -198px -211.9px;
}

.elo-input--phone .zm {
  background-position: -198px -228.2px;
}

.elo-input--phone .zw {
  background-position: -198px -244.5px;
}
