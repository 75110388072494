.elo-popover {
  $this: &;

  --elo-popover-border-radius: 8px;
  --elo-popover-background-color: var(--color-primary-neutral-white);
  --elo-popover-title-color: var(--color-primary-dg-brand);
  --elo-popover-title-font: var(--elo-heading-h6);
  --elo-popover-gap: 16px;
  --elo-popover-padding: 24px;
  --elo-popover-z-index: 1070;
  --elo-popover-space-between-buttons: 8px;

  --elo-popover-triangle-size: 7px;
  --elo-popover-triangle-distance-to-corner: 16px;

  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: var(--elo-popover-z-index);
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: max-content;
  gap: var(--elo-popover-gap);
  padding: var(--elo-popover-padding);
  border-radius: var(--elo-popover-border-radius);
  background: var(--elo-popover-background-color);
  box-shadow: var(--shadow-elevation-3);

  &__wrapper {
    position: relative;
    display: inline-flex;
  }

  &__title {
    font: var(--elo-popover-title-font);
    color: var(--elo-popover-title-color);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    gap: var(--elo-popover-space-between-buttons);
  }

  &__arrow {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: var(--elo-popover-triangle-size);
    border-color: transparent;
  }

  &--sm {
    --elo-popover-padding: 8px;
  }

  &__wrapper--no-portal {
    z-index: 14;
    #{$this}--left-top,
    #{$this}--left-center,
    #{$this}--left-bottom {
      left: calc(100% + var(--elo-popover-triangle-size));
    }

    #{$this}--right-top,
    #{$this}--right-center,
    #{$this}--right-bottom {
      left: auto;
      right: calc(100% + var(--elo-popover-triangle-size));
    }

    #{$this}--top-left,
    #{$this}--top-center,
    #{$this}--top-right {
      top: calc(100% + var(--elo-popover-triangle-size));
    }

    #{$this}--bottom-left,
    #{$this}--bottom-center,
    #{$this}--bottom-right {
      top: auto;
      bottom: calc(100% + var(--elo-popover-triangle-size));
    }

    #{$this}--top-left,
    #{$this}--bottom-left {
      left: calc(50% - var(--elo-popover-triangle-distance-to-corner) - var(--elo-popover-triangle-size));
    }

    #{$this}--top-center,
    #{$this}--bottom-center {
      left: 50%;
      transform: translateX(-50%);
    }

    #{$this}--top-right,
    #{$this}--bottom-right {
      transform: translateX(-100%);
      left: calc(50% + var(--elo-popover-triangle-distance-to-corner) + var(--elo-popover-triangle-size));
    }

    #{$this}--left-top,
    #{$this}--right-top {
      top: calc(50% - var(--elo-popover-triangle-distance-to-corner) - var(--elo-popover-triangle-size));
    }

    #{$this}--left-center,
    #{$this}--right-center {
      transform: translateY(-50%);
      top: 50%;
    }

    #{$this}--left-bottom,
    #{$this}--right-bottom {
      top: calc(50% + var(--elo-popover-triangle-distance-to-corner) + var(--elo-popover-triangle-size));
      transform: translateY(-100%);
    }
  }

  // placement
  &--left-top,
  &--left-center,
  &--left-bottom {
    .elo-popover__arrow {
      border-left-width: 0;
      border-right-color: var(--elo-popover-background-color);
      left: calc(var(--elo-popover-triangle-size) * -1);
    }
  }

  &--right-top,
  &--right-center,
  &--right-bottom {
    .elo-popover__arrow {
      border-right-width: 0;
      border-left-color: var(--elo-popover-background-color);
      left: 100%;
    }
  }

  &--top-left,
  &--top-center,
  &--top-right {
    .elo-popover__arrow {
      border-top-width: 0;
      border-bottom-color: var(--elo-popover-background-color);
      left: 50%;
      transform: translateX(-50%);
      top: calc(var(--elo-popover-triangle-size) * -1);
    }
  }

  &--bottom-left,
  &--bottom-center,
  &--bottom-right {
    .elo-popover__arrow {
      border-bottom-width: 0;
      border-top-color: var(--elo-popover-background-color);
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
    }
  }

  &--top-left,
  &--bottom-left {
    .elo-popover__arrow {
      left: calc(var(--elo-popover-triangle-distance-to-corner) + var(--elo-popover-triangle-size));
    }
  }

  &--top-right,
  &--bottom-right {
    .elo-popover__arrow {
      left: auto;
      right: calc(var(--elo-popover-triangle-distance-to-corner) - var(--elo-popover-triangle-size));
    }
  }

  &--left-top,
  &--right-top {
    .elo-popover__arrow {
      top: var(--elo-popover-triangle-distance-to-corner);
    }
  }

  &--left-center,
  &--right-center {
    .elo-popover__arrow {
      top: calc(50% - var(--elo-popover-triangle-size));
    }
  }

  &--left-bottom,
  &--right-bottom {
    .elo-popover__arrow {
      top: auto;
      bottom: var(--elo-popover-triangle-distance-to-corner);
    }
  }
}
