.payment-method-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
  margin-bottom: 15px;

  &--dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: var(--color-primary-dg-200);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  &--dot-active {
    width: 33px;
    height: 7px;
    border-radius: 4px;
    background-color: var(--color-primary-dg-brand);
    transition:
      width 0.3s ease,
      height 0.3s ease;
  }
}
