.elo-tag {
  --elo-tag-font: var(--elo-body-sm-regular);
  --elo-tag-background-color: var(--color-primary-cg-brand);
  --elo-tag-color: var(--color-primary-dg-brand);
  --elo-tag-icon-color: var(--color-primary-dg-300);

  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  background-color: var(--elo-tag-background-color);
  border-radius: 12px;
  gap: 8px;
  border: 1px solid rgba(0, 0, 0, 0);

  &.elo-tag--medium {
    padding: 4px 12px;
    border-radius: 8px;
  }

  &.elo-tag--large {
    padding: 8px 12px;
    border-radius: 8px;
  }

  &.elo-tag__selectable {
    &:hover {
      cursor: pointer;
      border-color: var(--elo-tag-icon-color);
    }

    &--selected {
      background-color: var(--color-utility-success-light);
      border-color: var(--color-highlight-web);

      &:hover {
        border-color: var(--color-highlight-brand);
      }
    }

    &.elo-tag__selectable--disabled {
      background-color: var(--color-primary-cg-200);
      border-color: var(--color-primary-cg-200);
      opacity: 0.5;
      cursor: default;

      &:hover {
        background-color: var(--color-primary-cg-200);
        border-color: var(--color-primary-cg-200);
      }

      .elo-tag__icon {
        cursor: default;
        color: var(--elo-tag-color);

        &:hover {
          cursor: default;
          color: var(--elo-tag-color);
        }
      }
    }
  }

  &__content {
    display: flex;
    color: var(--elo-tag-color);
    font: var(--elo-tag-font);
    align-items: center;
    min-height: 22px;
  }

  &__icon {
    cursor: pointer;
    display: flex;
    color: var(--elo-tag-color);

    &:hover {
      color: var(--elo-tag-icon-color);
    }

    &:active {
      color: var(--elo-tag-color);
    }
  }
}

@media (max-width: 568px) {
  .elo-tag {
    &.elo-tag--small,
    &.elo-tag--medium,
    &.elo-tag--large {
      padding: 12px 16px;
    }
  }
}

@media (max-width: 375px) {
  .elo-tag {
    &.elo-tag--small,
    &.elo-tag--medium,
    &.elo-tag--large {
      padding: 2px 12px;
    }
  }
}
